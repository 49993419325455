// 共架事業者に見せない項目（可否判定費用請求区分等）は設定しないこと

// 可否判定申込一覧
// 申込テーブル検索時の検索対象のフィールド名
export const orderListFields = [
  'Id', // 申込のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'OrderNo__c', // 申込番号（ここでは可否判定申込番号）
  'KoziTitle__c', // 工事件名
  'OrderKyogaZgsya__c', // 申込共架事業者（会社のId）
  'OrderCategory__c', // 申込区分
  'toLabel(OrderCategory__c)OrderCategoryName', // 申込区分の名称
  'StbType__c', // 設備種別
  'toLabel(StbType__c)StbTypeName', // 設備種別の名称
  'OrderDate__c', // 申込日
  'ProprietyJudgeOrderDate__c', // 可否判定申込日
  'Dsp_KoziPlaceMainDntNo__c', // 表示用 工事場所_代表電柱番号
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroCode__c', // 代表電柱_線路コード
  'KoziPlaceMainDntNo_SenroName__c', // 代表電柱_線路名
  'KoziPlacePrefectures__c', // 工事場所_都道府県
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'KoziPlacePlace__c', // 場所
  'KyogaType__c', // 共架種別
  'toLabel(KyogaType__c)KyogaTypeName', // 共架種別の名称
  'KyogasyaSendBackReason__c', // 共架者差戻し理由
  'OrderStatus__c', // 申込ステータス
  'toLabel(OrderStatus__c)OrderStatusName', // 申込ステータスの名称
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'TemporarilySavedDate__c', // 一時保存日
];

// 可否判定申込基本情報
// 本申込基本情報
// 申込テーブルの検索対象フィールド名
export const orderBasicFields = [
  'Id', // 申込のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'CheckJiko1__c', // 確認事項1
  'CheckJiko2__c', // 確認事項2
  'CheckJiko3__c', // 確認事項3
  'SyunkoReportCheckJiko1__c', // 竣工届確認事項1
  'SyunkoReportCheckJiko2__c', // 竣工届確認事項2
  'KyogasyaSendBackReason__c', // 共架者差戻し理由
  'KoziTitle__c', // 工事件名
  'OrderCategory__c', // 申込区分
  'KoziPlacePrefectures__c', // 工事場所_都道府県
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'Dsp_KoziPlaceMainDntNo__c', // 表示用 工事場所_代表電柱番号
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroCode__c', // 代表電柱_線路コード
  'KoziPlaceMainDntNo_SenroName__c', // 代表電柱_線路名
  'KyogaZgsyaCode__c', // 共架事業者コード
  'OrderDate__c', // 申込日
  'KyogaZgsyoCode__c', // 共架事業所コード
  'KoziPlacePlace__c', // 場所
  'MemoContents__c', // メモ内容
  'OrderNo__c', // 申込番号（可否判定申込番号 or 本申込番号）
  'ProprietyJudgeOrder__r.Id', // 可否判定申込のId
  'ProprietyJudgeOrder__r.LastModifiedDate', // 可否判定申込の最終更新日時
  'ProprietyJudgeOrder__r.OrderStatus__c', // 可否判定申込の申込ステータス
  'ProprietyJudgeOrderNo__c', // 可否判定申込番号
  'ProprietyJudgeOrderDate__c', // 可否判定申込年月日
  'OrderKyogaZgsya__c', // 申込共架事業者（会社のId）
  'OrderKyogaZgsya__r.Name', // 申込共架事業者名
  'OrderKyogaZgsya__r.Account__r.Name', // 申込共架事業所名
  'KyogaType__c', // 共架種別
  'toLabel(KyogaType__c)KyogaTypeName', // 共架種別の名称
  'ZizenNegotiationNo__c', // 事前協議番号
  'KyogaTanto__c', // 共架担当者
  'StbType__c', // 設備種別
  'toLabel(StbType__c)StbTypeName', // 設備種別の名称
  'SplitOrderUmu__c', // 分割申込有無
  'toLabel(SplitOrderUmu__c)SplitOrderUmuName', // 分割申込有無の名称
  'OrderStatus__c', // 申込ステータス
  'OrderType__c', // 申込種別
  'TemporarilySavedDate__c', // 一時保存日
  'MainDntNoNWZgsyo__c', // 代表電柱の中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  'MainDntNoNWZgsyo__r.Code__c', // 代表電柱の中電ＮＷ事業所コード
  'KdKisnTanto__c', // 強度計算担当者
  'ProprietyJudgeTanto__c', // 可否判定担当者
];

// 可否判定申込/本申込の契約電柱
// 契約電柱テーブルの検索対象フィールド名
export const orderDntFields = [
  'Id', // 契約電柱のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'SenroNameKana__c', // 線路名カナ
  'DrHksnHouseSideRikakKkh__c', // 電力引込_家屋_離隔確保
  'DrHksnHouseSideRikakKkhSynk__c', // 電力引込_家屋_離隔確保_竣工
  'SyunkoDate__c', // 竣工日
  'RepairKoziKinds__c', // 改修工事種類
  'DrHksnKiknCrossingRikak__c', // 電力引込径間交差_離隔(m)
  'DrHksnKiknCrossRikakSynk__c', // 電力引込径間交差_離隔(m)_竣工
  'KyogaType__c', // 共架種別
  'KyogaZgsyaCode__c', // 共架事業者コード
  'OrderNo__c', // 申込番号
  'Order__r.Id', // 申込のId
  'Order__r.LastModifiedDate', // 申込の最終更新日時
  'Order__r.CheckJiko1__c', // 申込の確認事項1
  'Order__r.CheckJiko2__c', // 申込の確認事項2
  'Order__r.CheckJiko3__c', // 申込の確認事項3
  'KyogaZgsyoCode__c', // 共架事業所コード
  'MainteKoziCompanyCode__c', // 保守工事会社コード
  'MaintenanceKoziCompany__c', // 保守工事会社（会社マスタ参照）
  'MaintenanceKoziCompany__r.Name', // 保守工事会社名
  'AttachSpotHeight__c', // 取付点高さ(m)
  'AttachSpotHeightSyunko__c', // 取付点高さ_竣工
  'SenroName__c', // 線路名
  'ClosestDrStbRikakOrder__c', // 最接電力設備_離隔_申込
  'ClosestDrStbRikakSyunko__c', // 最接電力設備_離隔_竣工
  'NWKoziHopeMemo__c', // NW工事希望_メモ
  'SerialNumber__c', // 通し番号
  'StbType__c', // 設備種別
  'DntCategory__c', // 電柱区分
  'AttachType__c', // 取付種別
  'SzbtItiId__c', // 支持物位置ID
  'StbKobetuId__c', // 設備個別ID
  'NWZgsyo__c', // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  'NWZgsyo__r.Name', // 中電ＮＷ事業所名
  'NWZgsyo__r.Code__c', // 中電ＮＷ事業所コード
  'OldNWZgsyo__c', // 旧中電ＮＷ事業所
  'SenroCode__c', // 線路コード
  'DntNo__c', // 電柱番号
  'IskkAiteZgsya1__c', // 一束化相手先事業者1（会社マスタ参照）
  'IskkAiteZgsya1__r.Name', // 一束化相手先事業者1 名称
  'IskkAiteZgsya2__c', // 一束化相手先事業者2（会社マスタ参照）
  'IskkAiteZgsya2__r.Name', // 一束化相手先事業者2 名称
  'IskkAiteZgsya3__c', // 一束化相手先事業者3（会社マスタ参照）
  'IskkAiteZgsya3__r.Name', // 一束化相手先事業者3 名称
  'ProprietyJudgeResult__c', // 可否判定結果
  'IskkCategory__c', // 一束化区分
  'KyogaCategory__c', // 共架区分
  'TsnHksnUmu__c', // 通信引込線有無
  'NWKoziHopeKoziContents__c', // NW工事希望_工事内容
  'KoziUndo__c', // 工事取止
  'ClosestDrStbName__c', // 最接近電力設備_名称
  'DntNoManualInput__c', // 電柱番号_自由入力
  'K6KansnNo__c', // 6kV幹線No
  'K6Bunk1__c', // 6kV分岐1
  'K6Bunk2__c', // 6kV分岐2
  'K6Bunk3__c', // 6kV分岐3
  'K22SzbtNo__c', // 22kV支持物番号
  'K22GatiCategory__c', // 22kV架地区分
  'K6K22HeigaCategory__c', // 6kV22kV併架区分
  'RecordType.DeveloperName', // レコードタイプ
  'ProprietyJudgeContractDnt__c', // 可否判定申込の契約電柱ID（契約電柱マスタ参照）。本申込から可否判定の写真を参照するときに使用する
  'ProprietyJudgeContractDnt__r.SerialNumber__c', // 可否判定申込の契約電柱IDの通し番号。ソートに使う
  'SyunkoReportAcceptedDate__c', // 竣工届受理日
  'ResubmitNecessity__c', // 再提出要否
  'ResubmitReason__c', // 再提出理由
  'Dsp_DntNo__c', // 表示用電柱番号
  'Dsp_SenroName__c', // 表示用線路名
  'KyogaPriceCategory__c', //  共架料区分
  'OtherMemo__c', // その他メモ
  'OtherKyokaRequirements__c', // その他許可条件
  'ProprietyJudgeDoneDate__c', // 可否判定実施日
  'ProprietyJudgeCostBillCategory__c', // 可否判定費用請求区分
  'ImpossibleReason__c', // 否理由
  'ProprietyJudgeRemarks__c', // 可否判定備考
  'ProprietyJudgeContentsMemo__c', // 可否判定内容メモ
  'RnsTkBosiTiskTyJudge__c', // 連鎖倒壊防止対策柱判定
  'AnswerDate__c', // 回答日
  'DrSideKoziRequiredPeriod__c', // 電力側工事所要期間
  'DrSideKoziUnnecessaryReason__c', // 電力側工事不要理由
  'AfterJudgingSafetyRate__c', // 審査後安全率
  'AfterJudgingTekigoJudge__c', // 審査後適合判定
  'BeforeJudgingSafetyRate__c', // 審査前安全率
  'BeforeJudgingTekigoJudge__c', // 審査前適合判定
  'HakksDoneDate__c', // 風圧荷重計算実施日
  'HakksApprover__c', // 風圧荷重計算承認者
  'HakksApprovalDate__c', // 風圧荷重計算承認日
  'HakksTanto__c', // 風圧荷重計算担当者
  'NWKoziHopeRemarks__c', // NW工事希望_備考
];

// 可否判定申込/本申込の線設備
// 線設備テーブルの検索対象フィールド名
export const orderSenStbFields = [
  'Id', // 線設備のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'SerialNumber__c', // 通し番号
  'SzbtItiId1__c', // 支持物位置ID1
  'SzbtItiId2__c', // 支持物位置ID2
  'StbKobetuId1__c', // 設備個別ID1
  'StbKobetuId2__c', // 設備個別ID2
  'SenroCode1__c', // 線路コード1
  'SenroCode2__c', // 線路コード2
  'SenroName1__c', // 線路名1
  'SenroName2__c', // 線路名2
  'SenroNameKana1__c', // 線路名カナ1
  'SenroNameKana2__c', // 線路名カナ2
  'DntNo1__c', // 電柱番号1
  'DntNo2__c', // 電柱番号2
  'DntCategory1__c', // 電柱区分1
  'DntCategory2__c', // 電柱区分2
  'KyogaCableSnsyu__c', // 共架ケーブル線種
  'KyogaCableGaikei__c', // 共架ケーブル外径(mm)
  'ClosestDrStbName__c', // 最接近電力設備_名称
  'ClosestDrStbRikakOrder__c', // 最接電力設備_離隔_申込
  'DrStbRikakSyunko__c', // 電力設備離隔(m)_竣工
  'KyogaCategory1__c', // 共架区分1
  'KyogaCategory2__c', // 共架区分2
  'Order__c', // 参照(可否判定申込/本申込)
  'Order__r.OrderNo__c', // 申込番号
  'Order__r.OrderCategory__c', // 申込区分
  'KyogaZgsya__c', // 参照(申込共架事業者)
  'ContractDnt1__c', // 参照(契約電柱始点側)
  'ContractDnt2__c', // 参照(契約電柱終点側)
  'DntNo1ManualInput__c', // 電柱番号1_自由入力
  'DntNo2ManualInput__c', // 電柱番号2_自由入力
  'K6KansnNo1__c', // 6kV幹線No1
  'K6KansnNo2__c', // 6kV幹線No2
  'K6BunkOne1__c', // 6kV分岐1_1
  'K6BunkOne2__c', // 6kV分岐1_2
  'K6BunkTwo1__c', // 6kV分岐2_1
  'K6BunkTwo2__c', // 6kV分岐2_2
  'K6BunkThree1__c', // 6kV分岐3_1
  'K6BunkThree2__c', // 6kV分岐3_2
  'K22SzbtNo1__c', // 22kV支持物番号1
  'K22SzbtNo2__c', // 22kV支持物番号2
  'K22GatiCategory1__c', // 22kV架地区分
  'K22GatiCategory2__c', // 22kV架地区分
  'K6K22HeigaCategory1__c', // 6kV22kV併架区分
  'K6K22HeigaCategory2__c', // 6kV22kV併架区分
  'Dsp_DntNo1__c', // 表示用電柱番号1
  'Dsp_DntNo2__c', // 表示用電柱番号2
  'Dsp_SenroName1__c', // 表示用線路名1
  'Dsp_SenroName2__c', // 表示用線路名2
  'AttachOrder__c', // 取付申込
  'AttachOrderNo__c', // 取付申込番号
  'OtherTokkiZiko__c', // その他特記事項
];

// 可否判定結果一覧/可否判定結果確認の明細部
// 申込テーブル検索時の検索対象のフィールド名
export const orderResultListFields = [
  'Id', // 申込のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'OrderNo__c', // 申込番号（ここでは可否判定申込番号）
  'KoziTitle__c', // 工事件名
  'OrderKyogaZgsya__c', // 申込共架事業者（会社のId）
  'OrderKyogaZgsya__r.Name', // 申込共架事業者名
  'OrderKyogaZgsya__r.Account__r.Name', // 申込共架事業所名
  'OrderCategory__c', // 申込区分
  'toLabel(OrderCategory__c)OrderCategoryName', // 申込区分の名称
  'StbType__c', // 設備種別
  'toLabel(StbType__c)StbTypeName', // 設備種別の名称
  'OrderDate__c', // 申込日
  'ProprietyJudgeOrderDate__c', // 可否判定申込日
  'Dsp_KoziPlaceMainDntNo__c', // 表示用 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroCode__c', // 代表電柱_線路コード
  'KoziPlaceMainDntNo_SenroName__c', // 代表電柱_線路名
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlacePrefectures__c', // 工事場所_都道府県
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'KoziPlacePlace__c', // 場所
  'KyogaType__c', // 共架種別
  'toLabel(KyogaType__c)KyogaTypeName', // 共架種別の名称
  'OrderStatus__c', // 申込ステータス
  'toLabel(OrderStatus__c)OrderStatusName', // 申込ステータスの名称
  // 'ProprietyJudgeCost__c', // 請求明細から可否判定費用（税込）を計算するので使用しない
  'ProprietyJudgeAnswerDate__c', // 可否判定回答日
  'ProprietyJudgeSendBackDate__c', // 可否判定差戻日
  'ProprietyJudgeReorderDate__c', // 可否判定再申込日
  'ProprietyResultAcceptedDate__c', // 可否判定結果受理日
  'KaHonsu__c', // 可本数
  'ConditionalKaHonsu__c', // 条件付可本数
  'HiHonsu__c', // 否本数
];

// 可否判定結果の明細部
// 契約電柱テーブル検索時の検索対象のフィールド名
export const orderResultContractDntFields = [
  'Id',
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'SenroName__c', // 線路名
  'SenroCode__c', // 線路コード
  'DntNo__c', // 電柱番号
  'DntCategory__c', // 電柱区分
  'ProprietyJudgeDoneDate__c', // 可否判定実施日
  'ProprietyJudgeResult__c', // 可否判定結果
  'ImpossibleReason__c', // 可否判定否理由
  'ProprietyJudgeRemarks__c', // 可否判定備考
  'IskkCategory__c', // 一束化区分
  'StbType__c', // 設備種別
  'AttachType__c', // 取付種別
  'NWKoziHopeKoziContents__c', // 中国電力NWへの工事希望/工事内容
  'NWKoziHopeMemo__c', // 中国電力NWへの工事希望/メモ
  'NWKoziHopeRemarks__c', // 中国電力NWへの工事希望/備考
  'Dsp_DntNo__c', // 表示用電柱番号
  'Dsp_SenroName__c', // 表示用線路名
  'SerialNumber__c', // 通し番号
];

// 本申込一覧/竣工届一覧
// 申込テーブル検索時の検索対象のフィールド名
export const formalOrderListFields = [
  'Id', // 申込のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'OrderKyogaZgsya__c', // 申込共架事業者（会社のId）
  'OrderCategory__c', // 申込区分
  'toLabel(OrderCategory__c)OrderCategoryName', // 申込区分の名称
  'OrderNo__c', // 申込番号(可否判定申込番号 or 本申込番号。申込区分で判別できる)
  'ProprietyJudgeOrderNo__c', // 可否判定申込番号
  'StbType__c', // 設備種別
  'toLabel(StbType__c)StbTypeName', // 設備種別の名称
  'KyogaType__c', // 共架種別
  'toLabel(KyogaType__c)KyogaTypeName', // 共架種別の名称
  'ProprietyJudgeOrderDate__c', // 可否判定申込日
  'OrderDate__c', // 申込日（ここでは本申込日)
  'StartNotificationDate__c', // 開始通知日
  'KoziTitle__c', // 工事件名
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlacePrefectures__c', // 工事場所_都道府県
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'KoziPlacePlace__c', // 場所
  'ProprietyJudgeAnswerDate__c', // 可否判定回答日
  'KaHonsu__c', // 可本数
  'ConditionalKaHonsu__c', // 条件付可本数
  'HiHonsu__c', // 否本数
  'SplitOrderUmu__c', // 分割申込有無
  'toLabel(SplitOrderUmu__c)SplitOrderUmuName', // 分割申込有無の名称
  'OrderStatus__c', // 申込ステータス
  'toLabel(OrderStatus__c)OrderStatusName', // 申込ステータスの名称
  'ProprietyJudgeOrder__r.Id', // 本申込に紐づく可否判定申込のId
  'ProprietyJudgeOrder__r.LastModifiedDate', // 本申込に紐づく可否判定申込のLastModifiedDate
  'ProprietyJudgeOrder__r.OrderStatus__c', // 本申込に紐づく可否判定申込の申込ステータス。期限切れチェック用
  'KyogasyaSendBackReason__c', // 共架者差戻し理由
  'TemporarilySavedDate__c', // 一時保存日
  'Dsp_KoziPlaceMainDntNo__c', // 表示用 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroCode__c', // 代表電柱_線路コード
  'KoziPlaceMainDntNo_SenroName__c', // 代表電柱_線路名
  'ContractNo__c', // 契約書番号
  'OrderDntHonsu__c', // 申込電柱本数
];

// 本申込/撤去の一束化設定契約電柱
// 契約電柱テーブルの検索対象フィールド名
export const iskkDntFields = [
  'Id', // 契約電柱のId
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'SenroNameKana__c', // 線路名カナ
  'KyogaType__c', // 共架種別
  'KyogaZgsyaCode__c', // 共架事業者コード
  'KyogaZgsyoCode__c', // 共架事業所
  'Order__c', // 参照(可否判定申込)
  'OrderNo__c', // 申込番号
  'KyogaZgsya__c', // 参照(申込共架事業者)
  'SenroName__c', // 線路名
  'SerialNumber__c', // 通し番号
  'StbType__c', // 設備種別
  'DntCategory__c', // 電柱区分
  'AttachType__c', // 取付種別
  'SzbtItiId__c', // 支持物位置ID
  'StbKobetuId__c', // 設備個別ID
  'NWZgsyo__c', // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  'NWZgsyo__r.Name', // 中電ＮＷ事業所名
  'NWZgsyo__r.Code__c', // 中電ＮＷ事業所コード
  'SenroCode__c', // 線路コード
  'DntNo__c', // 電柱番号
  'IskkAiteZgsya1__c', // 一束化相手先事業者1（会社マスタ参照）
  'IskkAiteZgsya1__r.Name', // 一束化相手先事業者1 名称
  'IskkAiteZgsya1__r.KyogaZgsyaCode__c', // 一束化相手先事業者1 共架事業者コード
  'IskkAiteZgsya1__r.KyogaZgsyoCode__c', // 一束化相手先事業者1 共架事業所コード
  'IskkAiteZgsya2__c', // 一束化相手先事業者2（会社マスタ参照）
  'IskkAiteZgsya2__r.Name', // 一束化相手先事業者2 名称
  'IskkAiteZgsya2__r.KyogaZgsyaCode__c', // 一束化相手先事業者2 共架事業者コード
  'IskkAiteZgsya2__r.KyogaZgsyoCode__c', // 一束化相手先事業者2 共架事業所コード
  'IskkAiteZgsya3__c', // 一束化相手先事業者3（会社マスタ参照）
  'IskkAiteZgsya3__r.Name', // 一束化相手先事業者3 名称
  'IskkAiteZgsya3__r.KyogaZgsyaCode__c', // 一束化相手先事業者3 共架事業者コード
  'IskkAiteZgsya3__r.KyogaZgsyoCode__c', // 一束化相手先事業者3 共架事業所コード
  'IskkCategory__c', // 一束化区分
  'KyogaCategory__c', // 共架区分
  'DntNoManualInput__c', // 電柱番号_自由入力
  'K6KansnNo__c', // 6kV幹線No
  'K6Bunk1__c', // 6kV分岐1
  'K6Bunk2__c', // 6kV分岐2
  'K6Bunk3__c', // 6kV分岐3
  'K22SzbtNo__c', // 22kV支持物番号
  'K22GatiCategory__c', // 22kV架地区分
  'K6K22HeigaCategory__c', // 6kV22kV併架区分
  'RecordType.DeveloperName', // レコードタイプ
  'Dsp_DntNo__c', // 表示用電柱番号
  'Dsp_SenroName__c', // 表示用線路名
];

// 請求明細検索、定期共架料内訳
// 請求明細テーブルの検索対象のフィールド名
export const billingDetailFields=[
  'Id', // 請求明細のId
  'BillingCostConfirmDate__c', // 請求費用/確定年月日
  'BillingDetailNo__c', // 請求明細番号
  'OrderNo__c', // 申込番号
  'BillingCategory__c', // 請求区分
  'toLabel(BillingCategory__c)BillingCategory', // 請求区分
  'KyogaType__c', // 共架種別コード
  'toLabel(KyogaType__c)KyogaType', // 共架種別
  'BillingTiming__c', // 請求タイミングコード
  'toLabel(BillingTiming__c)BillingTiming', // 請求タイミング
  'Year__c', // 年度
  'BillingHaseiDate__c', // 請求発生日
  'BillingStatus__c', // 請求ステータス
  'toLabel(BillingStatus__c)BillingStatus', // 請求ステータスラベル
  'KyogaZgsyaCode__c', // 共架事業者コード
  'KyogaZgsya__r.Account__r.Name', // 共架事業者
  'BillingDestination__c', // 請求先ID
  'BillingDestination__r.Name', // 請求先
  'toLabel(IskkCategory__c)IskkCategory', // 一束化区分
  'UnitPrice__c', // 単価
  'TransferHonsu__c', // 異動本数
  'FreeOfChargeTransferHonsu__c', // 無償異動本数
  'BillingAmount__c', // 請求金額
  'ConsumptionTax__c', // 消費税
  'BilReason__c', // 請求理由
  'TargetPeriodFrom__c', // 対象期間/FROM
  'TargetPeriodTo__c', // 対象期間/TO
  'LastModifiedDate', // 最終更新日
  'NotBillingReason__c', // 請求対象外理由
];

// 共架者連絡事項一覧
// 共架者連絡事項テーブルの検索対象のフィールド名
export const contactZikoListFields = [
  'Id',
  'IsDeleted',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'TrsyaUserId__c', // 登録者ユーザーID
  'UpsyaUserId__c', // 更新者ユーザーID
  'Name', // 件名
  'ApprovalStatus__c', // 承認ステータス
  'ContactType__c', // 連絡種別
  'Contact__c', // 通知先担当者
  'KyogaCheckDate__c', // 共架者確認日
  'NotificDate__c', // 通知日
  'NotificationContents__c', // 通知内容
];

// 代表電柱情報(取付)
// 契約電柱の編集対象フィールド
export const mainContractDntInfo = {
  Order__c: null, // 申込ID
  SerialNumber__c: 0, // 通し番号
  KyogaZgsya__c: null, // 共架事業者ID
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  DntCategory__c: null, // 電柱区分
  KyogaCategory__c: null, // 共架区分
  DntNo__c: null, // 電柱番号
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  StbType__c: null, // 設備種別
  KyogaType__c: null, // 共架種別
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  AttachType__c: null, // 取付種別（点設備は新規固定）
  AttachSpotHeight__c: 0.0, // 取付点高さ(m)
  ClosestDrStbRikakOrder__c: 0.00, // 最接近電力設備との離隔/離隔距離(m)/申請時
  DrHksnKiknCrossingRikak__c: 0.00, // 電力引込線との離隔(m)/径間途中,交差部分
  DrHksnHouseSideRikakKkh__c: false, // 電力引込_家屋_離隔確保_申込
  NWKoziHopeKoziContents__c: null, // NW工事希望_工事内容（呼び出し先でデフォルト値設定）
  AttachSpotHeightSyunko__c: 0.0, // 取付点高さ(m)_竣工
  ClosestDrStbRikakSyunko__c: 0.00, // 最接電力設備_離隔_竣工
  DrHksnKiknCrossRikakSynk__c: 0.00, // 電力引込径間交差_離隔(m)_竣工
  DrHksnHouseSideRikakKkhSynk__c: false, // 電力引込_家屋_離隔確保_竣工
};

// 代表電柱情報(撤去)
// 契約電柱の編集対象フィールド
export const mainTekyoContractDntInfo = {
  Order__c: null, // 申込ID
  SerialNumber__c: 0, // 通し番号
  KyogaZgsya__c: null, // 共架事業者ID
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  DntCategory__c: null, // 電柱区分
  KyogaCategory__c: null, // 共架区分
  DntNo__c: null, // 電柱番号
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  IskkCategory__c: null, // 一束化
  IskkAiteZgsya1__c: null, // 一束化相手先事業者1
  IskkAiteZgsya2__c: null, // 一束化相手先事業者2
  IskkAiteZgsya3__c: null, // 一束化相手先事業者3
  KyogaPriceCategory__c: null, // 共架料区分
  ContractDnt__c: null, // 親契約電柱
  StbType__c: null, // 設備種別
  KyogaType__c: null, // 共架種別
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  TekyoCategory__c: null, // 撤去区分 （呼び出し先でデフォルト値設定）
  TekyoSyunkoDate__c: null, // 撤去竣工日
  SyunkoReportAcceptedDate__c: null, // 竣工届受理日
};
