import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {isChudenNwContract} from '../../../common/common.js';

import MainContainer from '../../organisms/MainContainer.js';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import UsageRestrictions from './UsageRestrictions.js';
import getMenuItems from '../../organisms/MenuItems.js';

// eslint-disable-next-line no-unused-vars
import {MajorItem, MiddleItem, MinorItem} from '../../organisms/MenuItems.js';

import {reduxForm, getFormValues} from 'redux-form';
import {Modal} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    flexDirection: 'column',
  },
  menuContainer: {
    width: '95%',
    borderCollapse: 'collapse',
    backgroundColor: '#4F89C6',
    color: '#fff',
    textAlign: 'left',
    border: '1px solid black',
    marginTop: '10px',
    marginBottom: '10px',
  },
  menuItemL1empty: {
    backgroundColor: '#4F89C6',
    width: '10px',
    padding: 0,
    border: '1px solid #4F89C6',
  },
  menuItemL1: {
    backgroundColor: '#4F89C6',
    color: '#fff',
    textAlign: 'left',
    fontSize: '1.4em',
    border: 'none',
    padding: '8px',
  },
  menuItemL2empty: {
    backgroundColor: 'rgb(175, 171, 171)',
    width: '10px',
    padding: 0,
    border: 'none',
  },
  menuItemL2: {
    backgroundColor: 'rgb(175, 171, 171)',
    color: '#fff',
    textAlign: 'left',
    fontSize: '1.0em',
    border: 'none',
    padding: '8px',
  },
  menuItemL3: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    textAlign: 'left',
    fontSize: '1.0em',
    border: 'none',
    height: '20px',
    padding: 0,
    border: '1px solid rgb(175, 171, 171)',
  },
  menuButton: {
    width: '100%',
    textAlign: 'left',
  },
  imgLogo: {
    marginTop: '10px',
    marginLeft: '75px',
  },
  copyrightMenu: {
    textAlign: 'right',
    display: 'block',
    position: 'absolute',
    color: 'white',
    right: '4px',
    bottom: '4px',
  },
  messageArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 12,
  },
  helpDeskArea: {
    height: 'auto',
    width: 'auto',
    border: '1px solid red',
    background: 'white',
    padding: 10,
  },
  noticeArea: {
    height: 'auto',
    width: 'auto',
    fontSize: '1.016em',
    fontWeight: 'bold',
    border: '1px solid orange',
    background: 'white',
    padding: 10,
  },
  noticeTitle: {
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '1.1em',
  },
  noticeRed: {
    color: 'red',
  },
});

/**
 * メニュー画面 テンプレート。
 * 画面ID:1000/2000
 */
class Container extends React.Component {
  /**
   * コンストラクタ。
   * @param {object} props プロパティ
   */
  constructor(props) {
    super(props);

    // 利用制限通知ダイアログ表示判定
    this.state = {
      showModal: this.isShowModal(props),
    };
  }

  /**
   * 利用制限通知ダイアログ表示判定。
   * @param {object} props プロパティ
   * @return {boolean} 買取保留の場合true、そうでない場合false
   */
  isShowModal = (props) => {
    const {
      isHoldPurchase,
      userInfo,
    } = props;

    const account = userInfo.Account;

    // SFユーザの場合
    if (!account) {
      return false;
    }

    // ユーザが中国電力ネットワーク契約の場合
    if (isChudenNwContract(userInfo)) {
      return false;
    }

    // 買取保留状態の場合
    if (isHoldPurchase) {
      return true;
    }

    // 共架事業所マスタに「新規申込停止中」がTrueの場合
    const stopOrder = account.StopOrder__c;
    if (stopOrder) {
      return true;
    }

    return false;
  }

  /**
   * GRID単位(1列に入る項目)の要素生成。
   * @param {JSX.Element} children GRID内の要素
   * @return {JSX.Element} GRID単位の要素
   */
  rendarGrid = (children) => {
    return (
      <Grid item xs={12} sm={6} md={3}>
        {children}
      </Grid>
    );
  }

  /**
   * 大項目単位の要素生成。
   * @param {MajorItem} item 大項目単位のメニュー項目
   * @param {number} index インデックス
   * @return {JSX.Element} 大項目単位の要素
   */
  renderTableMajorItem = (item, index) => {
    const {classes} = this.props;
    const element = [];

    element.push(
        <TableRow key={'MJ_' + index}>
          <TableCell colSpan="3" className={classes.menuItemL1}>{item.name}</TableCell>
        </TableRow>,
    );

    const middleItems = item.items;
    for (let i = 0; i < middleItems.length; i++) {
      element.push(this.renderTableMiddleItem(middleItems[i], i));
    }

    return (
      <TableContainer
        component={Paper}
        className={classes.menuContainer}
      >
        <Table>
          <TableBody>
            {element}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  /**
   * 中項目単位の要素生成。
   * @param {MiddleItem} item 中項目単位のメニュー項目
   * @param {number} index インデックス
   * @return {JSX.Element} 中項目単位の要素
   */
  renderTableMiddleItem = (item, index) => {
    const {classes} = this.props;
    const element = [];

    element.push(
        <TableRow key={'MD_' + index}>
          <TableCell className={classes.menuItemL1empty}></TableCell>
          <TableCell colSpan="2" className={classes.menuItemL2}>{item.name}</TableCell>
        </TableRow>,
    );

    const minorItems = item.items;
    for (let i = 0; i < minorItems.length; i++) {
      element.push(this.renderTableMinorItem(minorItems[i], i));
    }

    return element;
  }

  /**
   * 小項目単位の要素生成。
   * @param {MinorItem} item 小項目単位のメニュー項目
   * @param {number} index インデックス
   * @return {JSX.Element} 小項目単位の要素
   */
  renderTableMinorItem = (item, index) => {
    const {classes, history} = this.props;
    const element = [];

    element.push(
        <TableRow key={'MN_' + index}>
          <TableCell className={classes.menuItemL1empty}></TableCell>
          <TableCell className={classes.menuItemL2empty}></TableCell>
          <TableCell className={classes.menuItemL3}>

            <Button
              componet={Link}
              onClick={() => {
                history.push(item.path, null, history.option.CLEAR);
              }}
              startIcon={<ArrowRightIcon />}
              className={classes.menuButton}
              disabled={!item.path}
            >
              <u className={classes.menuButton}>{item.name}</u>
            </Button>
          </TableCell>
        </TableRow>,
    );

    return element;
  }

  /**
   * 大項目単位の項目数カウント。
   * @param {MajorItem} majorItem 大項目単位のメニュー項目
   * @return {number} 大項目内の項目数
   */
  countLine = (majorItem) => {
    let count = 1;

    // 中項目の件数をカウント
    count += majorItem.items.length;

    // 小項目の件数をカウント
    for (const middleItem of majorItem.items) {
      count += middleItem.items.length;
    }

    return count;
  }

  /**
   * メニュー描画
   * @param {MajorItem[]} majorItems
   * @return {JSX.Element} テーブルの要素
   */
  renderTable = (majorItems) => {
    let columnTables = [];
    const columns = [];

    for (let i = 0; i < majorItems.length; i++) {
      const majorItem = majorItems[i];
      columnTables.push(this.renderTableMajorItem(majorItem, i));

      let isLastLine = false;
      if (i >= majorItems.length - 1) isLastLine = true;

      // 大項目2個毎に縦に並べる
      if (i % 2 == 1 || isLastLine) {
        columns.push(this.rendarGrid(columnTables));
        columnTables = [];
      }
    }

    return (
      <Grid container spacing={0}>
        {columns}
      </Grid>
    );
  };

  /**
   * 描画処理。
   * @return {JSX.Element} メニュー画面の要素
   */
  render() {
    const {classes, userInfo, generalPurpose} = this.props;

    // メニュー項目を取得
    const rows = getMenuItems('main', userInfo, generalPurpose);

    // フッダ
    const footerBtn = (
      <span className={classes.copyrightMenu}>
        Copyright © Chugoku Electric Power Transmission & Distribution Co.,Inc. All rights reserved.
      </span>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer props={this.props} disableSideMenu={true}
          footerBtn={footerBtn} bgImg={false}>
          {this.renderTable(rows)}

          <div className={classes.messageArea}>

            {/* <div> */}
            {
              /*
                空のメッセージ表示エリア。
                align-items: flex-start;
                となっているため、相談窓口div位置保持のために残す。

                左側の通知メッセージを表示させる時は、本divを削除し
                下記のclassName=noticeAreaのdivのコメントを外す。
              */}
            {/* </div> */}

            {/*
            <div className={classes.noticeArea}>
              <div className={classes.noticeTitle}>お知らせ</div>
              <span className={classes.noticeRed}>
                2025年2月1日（土）は終日メンテナンスのため、ユーポルのご利用ができません。<br/>
              </span>
              皆様には大変ご不便をおかけいたしますが、ご協力の程よろしくお願いいたします。<br/>
              ※なお、2025年2月2日（日）からは通常通りご利用いただける予定となっております。
            </div>
            */}

            {/*
            <div className={classes.helpDeskArea}>

            </div>
            */}

          </div>
        </MainContainer>

        <Modal
          open={this.state.showModal}
          onClose={this.closeModal}
        >
          <UsageRestrictions>
            <PositiveButton
              onClick={()=>{
                this.closeModal();
              }}
              variant="contained"
              size="large"
            >
              <span>閉じる</span>
            </PositiveButton>
          </UsageRestrictions>
        </Modal>
      </form>
    );
  }

  /**
   * モーダルのクローズ。
   */
  closeModal = () => {
    this.setState({showModal: false});
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurpose: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isHoldPurchase: PropTypes.boolean,
  userInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  generalPurpose: state.common.generalPurposeMap,
  isHoldPurchase: state.common.isHoldPurchase,
});

const mapDispatchToProps = {
};

const FORM_NAME = 'Menu';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);
